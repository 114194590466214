html{  
  scroll-behavior: smooth !important;
}

.App {
  text-align: center;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

/***************************/


/* Remove select border */

/* fieldset{
  border: none !important;
  outline: none !important;
} */


/* Home */

.bg-image {
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: 80%;
  background-repeat: no-repeat;
  opacity: 0.95;
  background-image: -webkit-linear-gradient(top, rgba(251, 252, 253, 1) 0%, rgba(251, 252, 253, 0.5) 5%, rgba(251, 252, 253, 0) 8%  ,rgba(251, 252, 253, 0) 70%, rgba(251, 252, 253, 1) 100%),
    url('https://saimaa.kuvat.fi/kuvat/Saimaa+Ringed+Seal/saimaa+ringed+seal+on+frozen+lake+seal+visit+saimaa.jpg?img=img4k');
}

#start {
  /* position: absolute; */
  /* width: 100%; */
  background-repeat: no-repeat;
  background: url('https://onlyone.imgix.net/assets/landing-page/waves-30x30-1.svg');
  background-size: auto;
}

.icon {
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes example {
  from {
    transform: translateY(0px);
    opacity: 0;
  }

  to {
    transform: translateY(5px);
    opacity: 1;
  }
}


.logo {
  height: 50px;
  position: absolute;
  z-index: -1;
  padding-right: 70px;
}

.Mobilelogo{
  height: 30px;
  position: absolute;
  z-index: -1;
  padding-right: 55px;
  padding-top: 2px;
}
/***************************/

/* Database */

/* .biggerimage {
  position: relative;
  margin: auto;
  width: 100%;
} */
/******************************/

/* Navbar */
/* .Popper{
  z-index: 5
} */

/******************************/

/* Footer */


.LUT{
  height: 90px;
}

.Coexists{
  height: 80px;
}

.cbc{
  height: 80px;
}









@media (max-width: 870px) {
  /* .image-label {
    font-size: 10px;
  } */
  .LUT{
    height: 70px;
    /* left: 5%; */
  }
  .Coexists{
    height: 60px;
  }
  .cbc{
    /* right: 5%; */
    height: 60px;
  }
}

@media (max-width: 330px) {
  /* .image-label {
    font-size: 10px;
  } */
  .LUT{
    height: 40px;
    /* left: 5%; */
  }
  .Coexists{
    height: 35px;
  }
  .cbc{
    /* right: 5%; */
    height: 35px;
  }
}


.viewpoint{
  
  padding: 1px 6px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.56);
  border: 1px solid rgba(0, 0, 0, 0.22);
  
  margin-bottom: 8px;
  /* margin-right: 30px; */
  display: inline-block !important;
  /* margin-left: 5px; */
  text-align: center;

  width: 30px;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;


  > span{
    font-size: 11px;
  }
    
  > span:first-child {
    display: none;
  }
  
}

.viewpoint:hover{
  background-color: rgba(0, 0, 0, 0.05);
  
}

.viewpoint__checked {
  background-color: #1976d2;
  color: white;
}

.viewpoint__checked:hover {
  background-color: #1565c0;
}


/******************************/

